// Core
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ReactPageScroller from '../lib/index';


// Instruments
import '../static/styles/index.scss';
import Layout from '../layouts/en';
import SEO from '../components/seo';
import MainSection from '../pages-sections/newsroom-sections/main-section/main-section';
// import PostsSection from '../pages-sections/newsroom-sections/posts-section/posts-section';
import ReleasesSection from '../pages-sections/newsroom-sections/releases-section/releases-section';
import SubscriptionSection from '../pages-sections/home-sections/subscription-section/subscription-section';

const AboutPage = ({ location, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [windowSize, setWindowSize] = useState(0);
  const [showSections, setShowSections] = useState(false);
  const [releases, setReleases] = useState(null);
  const isMobile = windowSize <= 1024;
  const wpData = data && data.allWordpressPage.edges.filter((item) => item.node.title === 'Newsroom')[0].node.acf;
  // const wpDataPosts = data && data.allWordpressPost.edges;
  const wpDataReleases = data && data.allWordpressWpPressReleases.edges;

  useEffect(() => {
    const releasesSliderData = [];
    let releasesSlider = [];
    wpDataReleases.forEach((item, i, arr) => {
      const { node: { acf: { file } } } = item;
      releasesSlider.push(file);
      if ((i + 1) % 4 === 0) {
        releasesSliderData.push(releasesSlider);
        releasesSlider = [];
      } else if (arr.length === i + 1) {
        releasesSliderData.push(releasesSlider);
        releasesSlider = [];
      }
    });
    setReleases(releasesSliderData);
  }, [data, setReleases, wpDataReleases]);

  const ref = useRef(null);

  const MENU_ITEMS = [
    // wpData.section_title_1,
    wpData.section_title_2,
  ];

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  const handleWindowScroll = () => {
    const scrollBlocker = window.pageYOffset > 0 || window.pageYOffset < 0;

    setCanScrollUp(scrollBlocker);
    return true;
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    if (!isMobile) {
      document.addEventListener('scroll', handleWindowScroll, { passive: false });
      setShowSections(false);
    } else {
      setShowSections(true);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      if (showSections !== (currentPage === MENU_ITEMS.length + 1)) {
        setShowSections(currentPage === MENU_ITEMS.length + 1);
      }
    }, 1000); // time for render sections that are out of pageScroller
  }, [currentPage]);

  if (windowSize === 0) {
    return (<></>);
  }
  const renderSections = () => (
    [
      <MainSection
        activeSection={currentPage}
        title={wpData.section_1_name}
        isMobile={isMobile}
        data={
                {
                  title: wpData.section_1_title,
                  titleBold: wpData.section_1_title_bold,
                  image: wpData.section_1_background_image,
                }
              }
      />,
      // <PostsSection
      //   activeSection={currentPage}
      //   posts={wpDataPosts}
      //   index={3}
      //   isMobile={isMobile}
      //   windowSize={windowSize}
      // />,
      <ReleasesSection
        activeSection={currentPage}
        title={wpData.section_title_2}
        isMobile={isMobile}
        index={1}
        data={releases}
      />,
    ]
  );
  return (
    <>
      <Layout
        currentSection={currentPage}
        sectionsCount={MENU_ITEMS.length + 1}
        location={location}
        isMobile={isMobile}
        showFooter={showSections}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title={wpData.section_1_name} />
        <div data-currentPage={`currentPage_${currentPage}`}>
          {windowSize > 1024 ? (
            <ReactPageScroller
              ref={ref}
              pageOnChange={(num) => setCurrentPage(num)}
              customPageNumber={currentPage}
              transitionTimingFunction="cubic-bezier(.435,.01,.135,1)"
              blockScrollUp={canScrollUp}
              scrollBlocker={showSections}
            >
              {renderSections().map((section) => section)}
            </ReactPageScroller>
          ) : renderSections().map((section) => section)}
        </div>
        { (showSections || isMobile)
          && (
          <SubscriptionSection
            activeSection={currentPage}
            data={
            {
              title: wpData.subscription_title,
              subTitle: wpData.subscription_sub_title,
              placeholder: wpData.subscription_placeholder,
              button: wpData.subscription_button,
              empty: wpData.email_empty,
              exist: wpData.email_exist,
              success: wpData.success,
            }
          }
          />
          )}
      </Layout>
    </>
  );
};

AboutPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
AboutPage.defaultProps = {
  data: null,
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {

            section_title_1
            section_title_2

            section_1_title
            section_1_title_bold
            section_1_name
            section_1_background_image {
              alt
              url
            }
          
            section_3_file_1 {
              title
              url
              caption
            }
            section_3_file_2 {
              title
              url
              caption
            }
            section_3_file_3 {
              title
              url
              caption
            }
            section_3_file_4 {
              title
              url
              caption
            }
            section_3_file_5 {
              title
              url
              caption
            }
            section_3_file_6 {
              title
              url
              caption
            }
            section_3_file_7 {
              title
              url
              caption
            }
            section_3_file_8 {
              title
              url
              caption
            }
            section_3_file_9 {
              title
              url
              caption
            }
            section_3_file_10 {
              title
              url
              caption
            }
            
            subscription_title
            subscription_sub_title
            subscription_placeholder
            subscription_button
            email_empty
            email_exist
            success

            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
    allWordpressPost(filter: {categories: {eq: 3}}) {
      edges {
        node {
          title
          id
          template
          content
          slug
          date(locale: "")
          acf {
            post_text
            post_title
          }
        }
      }
    }
    allWordpressWpPressReleases(
      filter: {
        categories: {
          eq: 20
        }
      }
      sort: {
          fields: modified,
          order: DESC
      }
    ) {
      edges {
        node {
          acf {
            file {
              title
              url
              caption
            }
          }
        }
      }
    }
  }`;
